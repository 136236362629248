import { ReportCategory, LogCategory, LogPredefinedValues, RadioItem, Gender, CivilStatus } from "@/types";
import {
  REPORT_CATEGORY_AGENT_CONTACT,
  REPORT_CATEGORY_CUSTOMER_CONTACT,
  REPORT_CATEGORY_COMPANY_SERVICE,
  REPORT_CATEGORY_AGENT_ABUSE,
  REPORT_CATEGORY_CUSTOMER_ABUSE,
  REPORT_CATEGORY_MISLEADING,
  REPORT_CATEGORY_ILLEGAL,
  REPORT_CATEGORY_OTHER,
  REPORT_CATEGORY_NEW_CATEGORY,
  REPORT_CATEGORY_BAD_QUALITY,
} from "@/types/constants";
import { hobbies } from "@/types/datasets/hobbies";
import { sexPreferences } from "@/types/datasets/sexPreferences";

export const reportedMessagesCategories: { agent: ReportCategory[]; customer: ReportCategory[] } = {
  agent: [
    { icon: "mdi-email-outline", text: REPORT_CATEGORY_AGENT_CONTACT, value: "contact" },
    { icon: "mdi-cards-playing-outline", text: REPORT_CATEGORY_COMPANY_SERVICE, value: "company services" },
    { icon: "mdi-skull", text: REPORT_CATEGORY_AGENT_ABUSE, value: "abusive operator" },
    { icon: "mdi-untappd", text: REPORT_CATEGORY_MISLEADING, value: "misleading" },
    { icon: "mdi-needle", text: REPORT_CATEGORY_ILLEGAL, value: "illegal" },
    { icon: "mdi-new-box", text: REPORT_CATEGORY_NEW_CATEGORY, value: "new category" },
    { icon: "mdi-thumb-down", text: REPORT_CATEGORY_BAD_QUALITY, value: "bad quality/answer is not relevant" },
    { icon: "mdi-help-circle-outline", text: REPORT_CATEGORY_OTHER, value: "other" },
  ],
  customer: [
    { icon: "mdi-email-outline", text: REPORT_CATEGORY_CUSTOMER_CONTACT, value: "contact" },
    { icon: "mdi-skull", text: REPORT_CATEGORY_CUSTOMER_ABUSE, value: "abusive customer" },
    { icon: "mdi-needle", text: REPORT_CATEGORY_ILLEGAL, value: "illegal" },
    { icon: "mdi-new-box", text: REPORT_CATEGORY_NEW_CATEGORY, value: "new category" },
    { icon: "mdi-thumb-down", text: REPORT_CATEGORY_BAD_QUALITY, value: "bad quality/answer is not relevant" },
    { icon: "mdi-help-circle-outline", text: REPORT_CATEGORY_OTHER, value: "other" },
  ],
};

function makeGroups(data: LogPredefinedValues[]): RadioItem[] {
  return data.map((group) => ({ label: group.title, value: group.name }));
}

function makePredefinedValues(data: LogPredefinedValues[]): object[] {
  return data.reduce(
    (acc, group) => [
      ...acc,
      { divider: true },
      { header: group.title },
      ...group.values.map((title) => ({ text: title, value: { title, group: group.name } })),
    ],
    [],
  );
}

export const logsCategories: Record<string, LogCategory> = {
  personal: {
    title: "Personal Info",
    icon: "mdi-information-outline",
    color: "blue darken-3",
    cssColor: "blue--text text--darken-3",
    groups: [
      { label: "Name", value: "name" },
      { label: "Age", value: "age" },
    ],
  },
  family: {
    title: "Family",
    icon: "mdi-nature-people",
    color: "light-green darken-3",
    cssColor: "light-green--text text--darken-3",
  },
  hobbies: {
    title: "Sport and Hobbies",
    icon: "mdi-puzzle",
    color: "pink darken-3",
    cssColor: "pink--text text--darken-3",
    groups: makeGroups(hobbies),
    predefinedValues: makePredefinedValues(hobbies),
  },
  work: {
    title: "Work",
    icon: "mdi-briefcase",
    color: "indigo darken-2",
    cssColor: "indigo--text text--darken-2",
  },
  sex: {
    title: "Sexual Preferences",
    icon: "mdi-gender-male-female",
    color: "pink lighten-1",
    cssColor: "pink--text text--lighten-1",
    groups: makeGroups(sexPreferences),
    predefinedValues: makePredefinedValues(sexPreferences),
  },
  health: {
    title: "Health",
    icon: "mdi-medical-bag",
    color: "red darken-2",
    cssColor: "red--text text--darken-2",
  },
  updates: {
    title: "Updates",
    icon: "mdi-update",
    color: "cyan darken-3",
    cssColor: "cyan--text text--darken-3",
  },
  other: {
    title: "Other",
    icon: "mdi-chart-bubble",
    color: "blue-grey darken-1",
    cssColor: "blue-grey--text text--darken-1",
  },
};

export const genders: Record<string, Gender> = {
  male: {
    icon: "mdi-gender-male",
    color: "blue",
  },
  female: {
    icon: "mdi-gender-female",
    color: "pink lighten-3",
  },
  couple: {
    icon: "mdi-account-multiple",
    color: "teal",
  },
  shemale: {
    icon: "mdi-gender-male-female",
    color: "purple",
  },
  user: {
    icon: "mdi-account",
    color: "blue-grey",
  },
  f: {
    icon: "mdi-gender-female",
    color: "pink lighten-3",
  },
  m: {
    icon: "mdi-gender-male",
    color: "blue",
  },
};

export function getGender(gender: string): Gender {
  return genders[gender] || { icon: "mdi-gender-male-female-variant", color: "gray" };
}

export const civilStatuses: Record<string, CivilStatus> = {
  living_together: {
    icon: "mdi-home-modern",
    title: "Living together",
  },
  single: {
    icon: "mdi-account",
    title: "Single",
  },
  married: {
    icon: "mdi-account-multiple",
    title: "Married",
  },
  relation: {
    icon: "mdi-sofa",
    title: "In relation",
  },
  living_apart_together: {
    icon: "mdi-city",
    title: "Living apart together",
  },
  divorced: {
    icon: "mdi-heart-broken",
    title: "Divorced",
  },
  widow: {
    icon: "mdi-skull",
    title: "Widow",
  },
};

export function getCivilStatus(status: string): CivilStatus {
  return civilStatuses[status] || { icon: "mdi-handshake", title: status };
}

export const smokingStatuses: Record<string, { icon: string; title: string }> = {
  smoking: {
    icon: "mdi-smoking",
    title: "Smoking",
  },
  no_smoking: {
    icon: "mdi-smoking-off",
    title: "No smoking",
  },
  Yes: {
    icon: "mdi-smoking",
    title: "Smoking",
  },
  No: {
    icon: "mdi-smoking-off",
    title: "No smoking",
  },
  true: {
    icon: "mdi-smoking",
    title: "Smoking",
  },
  false: {
    icon: "mdi-smoking-off",
    title: "No smoking",
  },
};

export const eyeColors: Record<string, { color: string; title: string }> = {
  brown: {
    color: "brown",
    title: "Brown eyes",
  },
  green: {
    color: "green darken-1",
    title: "Green eyes",
  },
  blue: {
    color: "light-blue darken-1",
    title: "Blue eyes",
  },
  gray: {
    color: "grey darken-1",
    title: "Gray eyes",
  },
  grey: {
    color: "grey darken-1",
    title: "Grey eyes",
  },
};

export function getEyeColor(eye: string): { color: string; title: string } {
  return eyeColors[eye] || { color: "grey", title: `${eye} eyes` };
}

export const hairColors: Record<string, { color: string; title: string }> = {
  brown: {
    color: "brown lighten-1",
    title: "Brown hair",
  },
  black: {
    color: "black",
    title: "Black hair",
  },
  blond: {
    color: "orange lighten-2",
    title: "Blond hair",
  },
  darkblond: {
    color: "orange darken-2",
    title: "Dark blond hair",
  },
  gray: {
    color: "grey",
    title: "Gray hair",
  },
  grey: {
    color: "grey",
    title: "Grey hair",
  },
  bald: {
    color: "grey lighten-4",
    title: "Bald",
  },
  red: {
    color: "red lighten-1",
    title: "Red color",
  },
  colored: {
    color: "pink",
    title: "Colored hair",
  },
};

export function getHairColor(hair: string): { color: string; title: string } {
  return hairColors[hair] || { color: "grey", title: `${hair} hair` };
}

export const transportations: Record<string, { icon: string; title: string }> = {
  none: {
    icon: "mdi-walk",
    title: "None",
  },
  public_transport: {
    icon: "mdi-bus",
    title: "Public transport",
  },
  bicycle: {
    icon: "mdi-bike",
    title: "Bicycle",
  },
  bike: {
    icon: "mdi-bike",
    title: "Bicycle",
  },
  moped: {
    icon: "mdi-motorbike",
    title: "Moped",
  },
  scooter: {
    icon: "mdi-motorbike",
    title: "Scooter",
  },
  motorbike: {
    icon: "mdi-motorbike",
    title: "Motorbike",
  },
  auto: {
    icon: "mdi-car-hatchback",
    title: "Auto",
  },
  mobility_scooter: {
    icon: "mdi-wheelchair-accessibility",
    title: "Mobility scooter",
  },
  taxi: {
    icon: "mdi-taxi",
    title: "Taxi",
  },
};

export function getTransportation(transport: string): { icon: string; title: string } {
  return transportations[transport] || { icon: "mdi-airballoon", title: transport };
}
