import { defineStore } from "pinia";
import { MessageDocument, ChatDocument } from "@/types/index";
import { QueueCount, StatisticsState } from "@/types";
import { useAuthenticatorStore } from "@/stores/authenticator";

export const useStatisticsStore = defineStore("statistics", {
  state: (): StatisticsState => ({
    messages: [],
    queueCounts: [],
    activeChat: null,
    chatMessages: [],
    nextChatDocPage: null,
    previousChatDocPage: null,
    nextPokeDocPage: null,
    previousPokeDocPage: null,
    pokes: [],
  }),

  getters: {
    reversedChatMessages: (state: StatisticsState) => {
      return [...state.chatMessages].reverse();
    },
  },

  actions: {
    async setInitialQueues() {
      const initialQueues = useAuthenticatorStore().user.geos.map((geo: string) => {
        const [languageCode, countryCode] = geo.split("_");
        return {
          locale: geo,
          count: 0,
          countryCode,
          languageCode,
        } as QueueCount;
      });
      this.queueCounts = initialQueues;
    },
    pushChatMessage(message: MessageDocument) {
      if (this.chatMessages.find((chatMessage) => chatMessage.id === message.id)) {
        return;
      }
      this.chatMessages.push(message);
    },
    removeQueueCount(unclaimedChat: ChatDocument) {
      const index = this.queueCounts.findIndex((item: QueueCount) => item.locale === unclaimedChat.locale);
      if (index !== -1) {
        this.queueCounts[index].count -= 1;
      }
    },
    addQueueCount(unclaimedChat: ChatDocument) {
      const [languageCode, countryCode] = unclaimedChat.locale.split("_");

      const index = this.queueCounts.findIndex((item: QueueCount) => item.locale === unclaimedChat.locale);
      if (index !== -1) {
        this.queueCounts[index].count += 1;
        return;
      }
      this.queueCounts.push({
        locale: unclaimedChat.locale,
        languageCode,
        countryCode,
        count: 1,
      });
    },
    // updateQueueCount function created for constructChangeHandler inside ChatRepository.
    // it is shared together with chat module and requires three arguments. empty updateQueueCount
    // created to provide three arguments every  time constructChangeHandler is used.
    updateQueueCount() {
      return;
    },
  },
});
